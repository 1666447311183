//Dashboard
export const DUE_DATE_SPAN = [
    { key: "30 Days", value: "30" },
    { key: "5 Days", value: "5" },
    { key: "90 Days", value: "90" },
    { key: "60 Days", value: "60" },
    { key: "15 Days", value: "15" },

]

//Letter Tab
export const LETTER_GSTIN = [
    { key: "1", value: "07AAACT1507C1ZZ" },
]
export const MULTIPLE_LETTER = [
    { key: "Y", value: "Yes" },
    { key: "N", value: "No" },
]
export const PROCEED_TO_SCN = [
    { label: "SCN Triggered", value: "SCN TRIGGERED" },
    { label: "No Response", value: "NO RESPONSE" },
    { label: "Closed", value: "CLOSED" },
]
export const CORRESPOND_INV_AMT = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
//  Type of Issuing Authority*
export const ISSUING_AUTHORITY_TYPE = [
    { label: 'CGST', value: "CGST" },
    { label: 'SGST', value: "SGST" },
    { label: 'DGGI', value: "DGGI" },
    { label: 'ANTI-EVASION', value: "ANTI-EVASION" },
    { label: 'PREVENTIVE', value: "PREVENTIVE" },
    { label: 'SYSTEM GENERATED', value: "SYSTEM GENERATED" },
    { label: 'AUDIT', value: "AUDIT" },
    { label: 'OTHERS', value: "OTHERS" },
]
// TYPE_FORM_NOTICE
export const TYPE_FORM_NOTICE = [
    { label: 'FORM GST EWB-04', value: "FORM GST EWB-04" },
    { label: 'FORM GST DRC - 17', value: "FORM GST DRC - 17" },
    { label: 'FORM GST DRC - 16', value: "FORM GST DRC - 16" },
    { label: 'ANTI-FORM GST DRC - 13', value: "ANTI-FORM GST DRC - 13" },
    { label: 'FORM GST DRC - 10', value: "FORM GST DRC - 10" },
    { label: 'FORM GST DRC - 1A', value: "FORM GST DRC - 1A" },
    { label: 'FORM GST DRC - 01C', value: "FORM GST DRC - 01C" },
    { label: 'FORM ASMT-10', value: "FORM ASMT-10" },
    { label: 'ASMT-06', value: "ASMT-06" },
    { label: 'ASMT-02', value: "ASMT-02" },
    { label: 'GSTR-3A', value: "GSTR-3A" },
    { label: 'OTHERS', value: "OTHERS" },
]


// ISSUED_US
export const ISSUED_US = [
    { value: 'Section 54', label: "SECTION 54" },
    { value: 'Section 61', label: "SECTION 61" },
    { value: 'Section 70', label: "SECTION 70" },
    { value: 'Section 73', label: "SECTION 73" },
    { value: 'Section 74', label: "SECTION 74" },
    { value: 'Section 76', label: "SECTION 76" },
    { value: 'Section 79', label: "SECTION 79" },
    { value: 'Section 129', label: "SECTION 129" },
    { value: 'Section 130', label: "SECTION 130" },
    { value: 'OTHERS', label: "OTHERS" },
]

// REPLY_FORM_TYPE
export const REPLY_FORM_TYPE = [
    { label: 'FORM GST ASMT-03', value: "FORM GST ASMT-03" },
    { label: 'FORM GST ASMT-11', value: "FORM GST ASMT-11" },
    { label: 'OTHERS', value: "OTHERS" },
]

// PAYMENT
export const PAYMENT = [
    { label: 'DRC-03', value: "DRC-03" },
    { label: 'GSTR-3B', value: "GSTR-3B" },
    { label: 'OTHERS', value: "OTHERS" },
]

// PAYMENT_MODE
// export const MODE_OF_PAYMENT = [
//     { label: 'DRCITC', value: "DRCITC" },
//     { label: 'Cash', value: "CASH" },
//     { label: 'Both-03', value: "BOTH-03" },
// ]

// EXTENSION_Time_GRANTED
export const EXTENSION_Time_GRANTED = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

// WHETHER_WRIT_APPLICATION
export const WHETHER_WRIT_APPLICATION = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

export const ISSUE_CONCLUSION_FAVOURABLE_ONLY = [
    { key: "F", value: "Favorable" },
]

export const ISSUE_CONCLUSION_NOTICE = [
    { key: "O", value: "Open" },
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" }
]

export const ISSUE_CONCLUSION = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" }
]
export const FAVOURABLE_OPTION = [
    { key: "F", value: "Favorable" },
]

export const UNFAVOURABLE_OPTION = [
    { key: "U", value: "Unfavorable" },
    { key: "A", value: "Admitted" }

]
export const ISSUE_CONCLUSION_A = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "R", value: "Remanded Back" },
]
export const ISSUE_CONCLUSION_3 = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "PF", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" },
]

export const ISSUE_CONCLUSION_COMMISSIONER = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "PF", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" },
]

export const ISSUE_CONCLUSION_COMMISSIONERS = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "R", value: "Remanded Back" },
    { key: "PF", value: "Partly Favorable" },
    { key: "PR", value: "Partly Remanded Back" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" },
]

export const MEMORANDOM_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "R", value: "Remanded Back" },
    { key: "PR", value: "Partly Remanded Back" },

]

export const DESIGNATION_PHASE3 = [
    { key: "C", value: "Commissioner" },
    { key: "JC", value: "Joint Commissioner" },
    { key: 'ADC', value: "Appellate Deputy Commissioner" },
    { key: "AC", value: "Additional Commissioner " },

]


export const ISSUE_RISK_PARAM = [
    { key: "L", value: "Low" },
    { key: "H", value: "High" },
    { key: "M", value: "Moderate" }
]

// Division Block
export const ISSUE_DIVISION = [
    { key: "N", value: "North" },
    { key: "S", value: "South" },
    { key: "E", value: "East" }
]


//Adjudication Authority Tab
export const ADJ_PH1_ADJUNOT = [
    { label: "Yes", value: true },
    { label: "No", value: false },
]
export const ADJ_PH1_PRECEDED = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

export const ADJ_ATTEND = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

export const ADJ_ORDER_PASSED_BY = [
    { key: "AC", value: "Additional Commissioner " },
    { key: "DC", value: "Deputy Commissioner" },
    { key: "JC", value: "Joint Commissioner" },
    { key: "C", value: "Commissioner" },
    { key: "ASC", value: "Assistant commissioner" },
    { key: "S", value: "Superintendent" },
]
export const ADJ_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
]

export const APT_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "A", value: "Admitted" }
]

export const COMMISSIONER_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "R", value: "Remanded Back" },
    { key: "PF", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
    { key: "PR", value: "Partly Remanded Back" },
]

export const ADJ_APPELPRE = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const APPEAL_DECISION = [
    { label: 'Yet to decide', value: true },
    { label: 'Decided', value: false }
]
export const ADJ_WRITPRE = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const ADJ_DEPTPRE = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const ADJ_HAS_DEPT_APPEK = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]


//Appellate Authority Tab
export const APPA_PH1_APPELUS = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' }
]
export const Apa_PH1_PREDEPOAPP = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const APA_PH1_PAY_MODE = [
    { key: 'C', value: 'Cash' },
    { key: 'I', value: 'ITC' },
    { key: 'I', value: 'Both' },
    { key: 'Electronic Credit Ledger', value: 'Electronic Credit Ledger' },
    { key: 'Electronic Cash Ledger ', value: 'Electronic Cash Ledger ' },
]
//Appellate Tribunal Tab
//HC

export const APPEAL_AGAINST = [
    { key: "OIO", value: "OIO" },
    { key: "SCN", value: "SCN" },
    { key: "OIA", value: "OIA" },
    { key: "W", value: "WRIT" },
    { key: 'TO', value: 'TRIBUNAL ORDER' }
]

// filter
export const DT_FILTER_STATUS = [
    'OPEN',
    'CLOSED',
    'PENDING FOR REPLY',
    'PENDING FOR HEARING',
    'PENDING FOR ORDER',
    'APPEAL YET TO BE FILED',
    'DEPT RESPONSE PENDING',
    'WRIT YET TO BE FILED',
    'NO RESPONSE',
];

export const FILTER_STATUS_DATA = [
    'OPEN',
    'CLOSED',
    'PENDING FOR REPLY',
    'PENDING FOR HEARING',
    'PENDING FOR ORDER',
    'APPEAL YET TO BE FILED',
    'CALL BOOK',
    'WRIT YET TO BE FILED',
    'NO RESPONSE',
];
// filter
export const FILTER_STATUS = [
    { key: "OPEN", value: "OPEN" },
    { key: "CLOSE", value: "CLOSED" },
    { key: "REPLY", value: "PENDING FOR REPLY" },
    { key: "HEARING", value: "PENDING FOR HEARING" },
    { key: "ORDER", value: "PENDING FOR ORDER" },
    { key: "YFILED", value: "APPEAL YET TO BE FILED" },
    { key: "CALL BOOK", value: "CALL BOOK" },
    { label: "WRIT YET TO BE FILED", value: "WRIT YET TO BE FILED" },
    { label: "No Response", value: "NO RESPONSE" }
]
// filter Audit
export const FILTER_STATUS_AUDIT = [
    { key: "OPEN", value: "OPEN" },
    { key: "CLOSE", value: "CLOSED" },
    { key: "CLOSED_SCN_ISSUED", value: "CLOSED SCN ISSUED" },
]

// GST Rate
export const GST_RATE = [
    { key: "0.00", value: "0.00%" },
    { key: "0.10", value: "0.10%" },
    { key: "0.25", value: "0.25%" },
    { key: "1.00", value: "1.00%" },
    { key: "1.50", value: "1.50%" },
    { key: "3.00", value: "3.00%" },
    { key: "5.00", value: "5.00%" },
    { key: "6.00", value: "6.00%" },
    { key: "7.50", value: "7.50%" },
    { key: "12.00", value: "12.00%" },
    { key: "18.00", value: "18.00%" },
    { key: "28.00", value: "28.00%" },
]
//Audit Module
//company gst
export const YES_NO = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]
export const INT_RATE = [
    { label: '18%', value: '18' },
    { label: '24%', value: '24' },    
    { label: '0%', value: '0' },
]

export const AUDIT_GSTCHRG = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const AUDIT_WHFOLLOWUP = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const DRC_PAYMENT_CHALLAN = [
    { key: "CLOSED_SCN_ISSUED", value: "CLOSED SCN ISSUED" },
    // { key: "CLOSED_WITH_PYMT_LIB", value: "CLOSED WITH PYMT LIB" },
    { key: "CLOSED", value: "CLOSED" },
    { key: "OPEN", value: "OPEN" },
]
export const PAYMENT_MODE = [
    { key: "Cash", value: "CASH" },
    { key: "ITC", value: "ITC" },
    { key: "Both", value: "BOTH" },
]
export const FIN_YEAR = [
    { key: "1", value: "2017-2018" },
    { key: "2", value: "2018-2019" },
    { key: "3", value: "2019-2020" },
    { key: "4", value: "2020-2021" },
    { key: "5", value: "2021-2022" },
    { key: "6", value: "2022-2023" },
    { key: "7", value: "2023-2024" },

]
/*Other specific items*/
export const OTHER_SPECIFIC_ITEM = [
    { key: "osi1", value: "Input Service Distributor" },
    { key: "osi2", value: "Position on Cross Charge between Branches" },
    { key: "osi3", value: "Valuation for Transaction between Related Parties including Cost Sharing" },
    { key: "osi4", value: "Valuation for Stock Transfer of Goods to Branches" },
    { key: "osi5", value: "Job Work Movement" },
    { key: "osi6", value: "E-Way Bill Process" },
    { key: "osi7", value: "Employer-Employee Transactions" },
    { key: "osi8", value: "Permissions from Department like LUT" },
    { key: "osi9", value: "GST TDS/TCS Process" },
    { key: "osi10", value: "Brand & License Usage by Subsidiary/Related Companies" },


]
export const SPECIFIC_ITEM_TREATMENT = [
    { key: "sit1", value: "Works Contract Services (or) Goods/Services used for Construction of Plant & Machinery including foundation or structural support" },
    { key: "sit2", value: "GST charged by Airlines (apart from GST charged by Air Travel Agent)" },
    { key: "sit3", value: "GST on Reimbursement bills by CHA’s/ Freight Forwarders" },


]
export const ITC_INTELIGIBLE_CREDIT = [
    { key: "iic1", value: "Motor Vehicles for Transportation of Passengers (Less than 13 Pax)" },
    { key: "iic2", value: "General Insurance, Servicing, Repair & Maintenance of above" },
    { key: "iic3", value: "Food & Beverages including Outdoor Catering" },
    { key: "iic4", value: "Leasing, renting or hiring of motor vehicles for Passenger Transportation" },
    { key: "iic5", value: "Life Insurance" },
    { key: "iic6", value: "Health Insurance" },
    { key: "iic7", value: "Works Contract Services (or) Goods/Services used in Construction – to the extent of Capitalization" },
    { key: "iic8", value: "Goods lost, stolen, destroyed, written off or disposed of by way of gift or free samples" },
    { key: "iic9", value: "Personal Items:Beauty treatment, Health services, Cosmetic and Plastic surgery,Membership of a Club, Health and Fitness Centre,Travel benefits to employees on vacation such as leave or home travel concession,Goods or Services or both used for personal consumption" }


]
export const REVERSE_CHARGE_SERVICE = [
    { key: "rcs1", value: "Goods Transport Agency" },
    { key: "rcs2", value: "Legal Services from Advocates" },
    { key: "rcs3", value: "Sponsorship Services" },
    { key: "rcs4", value: "Non-Executive or Independent Director" },
    { key: "rcs5", value: "Ocean Import CIF Freight " },
    { key: "rcs6", value: "Security Services" },
    { key: "rcs7", value: "Renting of a Motor Vehicle (Rent-a-Cab)" },
]

export const SERVICE_FROM_GOV = [
    { key: "sfg1", value: "Goods Transport Agency" },
    { key: "sfg2", value: "Legal Services from Advocates" },
    { key: "sfg3", value: "Sponsorship Services" },
    { key: "sfg4", value: "Non-Executive or Independent Director" },
    { key: "sfg5", value: "Ocean Import CIF Freight " }

]
export const ITC_REV_TYPE = [
    { key: "irt1", value: "Rule 37 - Reversal of ITC on account of Non-Payment to Vendors within 180 days" },
    { key: "irt2", value: "Rule 39 - Reversal of ITC distributed by ISD" },
    { key: "irt3", value: "Rule 42 - Reversal of common credit for Input Services & Capital Goods" },
    { key: "irt4", value: "Rule 43 - Reversal of common credit for Capital Goods" },
    { key: "irt5", value: "Reversal U/S 17(4) of the CGST Act 50% Reversal of ITC for banking company" },
    { key: "irt6", value: "Reversal U/S 17(5) of the CGST Act Blocked Credit" },
    { key: "irt7", value: "Reversal U/S 18(6) of the CGST Act Reversal of ITC on Sale of Capital Goods" },
    { key: "irt8", value: "Reversal of TRAN-I credit" },
    { key: "irt9", value: "Reversal of TRAN-II credit" },
    { key: "irt10", value: "Any Other Reversal" },

]
export const ITC_REV_REF = [
    { key: "irr1", value: "Sec 17(2)" },
    { key: "irr2", value: "Sec 17(4)" },
    { key: "irr3", value: "Sec 16(2)" },
    { key: "irr4", value: "Sec 18(6)" },
    { key: "irr5", value: "Rule 36(4)" },
    { key: "irr6", value: "-" },

]
/*Other specific items*/

/*Pre gst litigation */
export const TYPE_OF_ACT = [
    { key: "EXCISE", value: "EXCISE" },
    { key: "CUSTOMS DUTY", value: "CUSTOMS DUTY" },
    { key: "VAT", value: "VAT" },
    { key: "SERVICE TAX", value: "SERVICE TAX" },
    { key: "CST", value: "CST" },
    { key: "OTHER TAX", value: "ENTRY TAX" },
]

/*Pre gst litigation*/
export const MODE_OF_RECEIPT = [
    { key: "By Post", value: "BY POST" },
    { key: "By Hand", value: "BY HAND" },
    { key: "By Email", value: "BY EMAIL" },
    { key: "On Portal", value: "ON PORTAL" },
]

export const MODE_OF_BGSTATUS = [
    { key: "Active", value: "Active" },
    { key: "Expired", value: "Expired" },
]
export const MODE_OF_PH = [
    { key: "Physical", value: "Physical" },
    { key: "Video Conference", value: "Video Conference" },
]
export const MODE_OF_TIME_PH = [
    { key: "08:00 AM", value: "08:00 AM" },
    { key: "08:30 AM", value: "08:30 AM" },
    { key: "09:00 AM", value: "08:00 AM" },
    { key: "09:30 AM", value: "09:30 AM" },
    { key: "10:00 AM", value: "10:00 AM" },
    { key: "10:30 AM", value: "10:30 AM" },
    { key: "11:00 AM", value: "11:00 AM" },
    { key: "11:30 AM", value: "11:30 AM" },
    { key: "12:00 PM", value: "12:00 PM" },
    { key: "12:30 PM", value: "12:30 PM" },
    { key: "01:00 PM", value: "01:00 PM" },
    { key: "01:30 PM", value: "01:30 PM" },
    { key: "02:00 PM", value: "02:00 PM" },
    { key: "02:30 PM", value: "02:30 PM" },
    { key: "03:00 PM", value: "03:00 PM" },
    { key: "03:30 PM", value: "03:30 PM" },
    { key: "04:00 PM", value: "04:00 PM" },
    { key: "04:30 PM", value: "04:30 PM" },
    { key: "05:00 PM", value: "05:00 PM" },
    { key: "05:30 PM", value: "05:30 PM" },
    { key: "06:00 PM", value: "06:00 PM" },
    { key: "06:30 PM", value: "06:30 PM" },
    { key: "07:00 PM", value: "07:00 PM" },
    { key: "07:30 PM", value: "07:30 PM" },
    { key: "08:00 PM", value: "08:00 PM" },
    { key: "08:30 PM", value: "08:30 PM" },
    { key: "09:00 PM", value: "09:00 PM" },
    { key: "09:30 PM", value: "09:30 PM" },
    { key: "10:00 PM", value: "10:00 PM" },
]

export const DT_Financial_Years: string[] = (() => {
    const startYear = 2001;
    const currentYear = new Date().getFullYear() + 1; // Current year + 1
    const years: string[] = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(`${year}-${year + 1}`);
    }
    return years;
})();


export const QUARTER = [
    { key: "Q1", value: "Q1", disabled: "disabled" },
    { key: "Q2", value: "Q2", disabled: "disabled" },
    { key: "Q3", value: "Q3", disabled: "disabled" },
    { key: "Q4", value: "Q4", disabled: "disabled" }
]

/*Pre gst litigation */

//common


export const paginationPrams = {
    pageLen: 10,
    pageLen50: 50,
    pageOption: [10, 50, 100, 200]
}

export const TAB_TYPE = [
    { key: "scn", value: "Notice/Letter" },
    { key: "aja", value: "Adjudication Authority" },
    { key: "apa", value: "Appellate Authority" },
    { key: "apt", value: "Appellate Tribunal" },
    { key: "hc", value: "High Court " },
    { key: "sc", value: "Supreme Court" }
]
export const PRE_ACT_TYPE = [
    // { key: "preaja", value: "Adjudication Authority"},
    // { key: "preapa", value: "Appellate Authority"},
    { key: "preapt", value: "Appellate Tribunal" },
    { key: "prehc", value: "High Court " },
    { key: "presc", value: "Supreme Court" }
]
export const AUDIT_ACT_TYPE = [
    // { key: "preaja", value: "Adjudication Authority"},
    // { key: "preapa", value: "Appellate Authority"},
    { key: "OPEN", value: "open" },
    { key: "CLOSED", value: "closed " },
    { key: "CLOSED SCN ISSUED", value: "closed scn issues" }
]
export const ADJ_ACT_TYPE = [
    // { key: "preaja", value: "Adjudication Authority"},
    // { key: "preapa", value: "Appellate Authority"},
    // { key: "apa", value: "Appellate Authority"},
    { key: "apt", value: "Appellate Tribunal" },
    { key: "hc", value: "High Court " },
    { key: "sc", value: "Supreme Court" }
]

export const ACT_TYPE = [
    // { key: "preaja", value: "Adjudication Authority"},
    // { key: "preapa", value: "Appellate Authority"},
    { key: "apa", value: "Appellate Authority" },
    { key: "apt", value: "Appellate Tribunal" },
    { key: "hc", value: "High Court " },
    { key: "sc", value: "Supreme Court" }
]

export const PRE_TAB_TYPE = [
    { key: "preaja", value: "Adjudication Authority" },
    { key: "preapa", value: "Appellate Authority" },
    { key: "preapt", value: "Appellate Tribunal" },
    { key: "prehc", value: "High Court" },
    { key: "presc", value: "Supreme Court" }
]
export interface LoaderState {
    show: boolean;
}
export const taxpayerTypes = [
    { key: 'NORMAL', displayName: 'Normal' },
    { key: 'SEZ', displayName: 'SEZ' },
    { key: 'ISD', displayName: 'Input Service Distributor' },
    { key: 'ECOM', displayName: 'E-commerce Operator' },
    { key: 'TDS', displayName: 'TDS Deductor' },
    { key: 'NRTP', displayName: 'Non-resident taxable Person' },
    { key: 'OIDT', displayName: 'Person supplying online information and database access' },
    { key: 'UIN', displayName: 'UIN-holders' },
    { key: 'CD', displayName: 'Taxpayer opting for composition scheme' },
    { key: 'TRANSPORTER', displayName: 'Transporter' }
]
export const UserRoleMap = {
    'Maker': { role: 'Maker', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'D_all_err_warn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'GSTR3Upload', 'ChooseGSTR3Files'] },
    'Preparer': { role: 'Preparer'/*checker*/, access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'Consultant': { role: 'Consultant'/*checker*/, access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'Signatory': { role: 'Signatory', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'Admin': { role: 'Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'Guest': { role: 'Guest', access: [] },
    'Viewer': { role: 'Viewer', access: [] }, // only for LMS
    'NoRole': { role: 'NoRole', access: [] },
    'Generator': { role: 'Generator', access: ['NewInvoice', 'DownloadSampleTemplate'] },
    'Reviewer': { role: 'Reviewer', access: ['NewInvoice', 'DownloadSampleTemplate'] },
    'GST_Admin': { role: 'GST_Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'GST_Preparer': { role: 'GST_Preparer', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'GST_Viewer': { role: 'GST_Viewer', access: [] },
    'GST_Consultant': { role: 'GST_Consultant', access: [] },
    'PREGST_Admin': { role: 'PREGST_Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'PREGST_Preparer': { role: 'PREGST_Preparer', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'PREGST_Viewer': { role: 'PREGST_Viewer', access: [] },
    'PREGST_Consultant': { role: 'PREGST_Consultant', access: [] },
    'DT_Admin': { role: 'DT_Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'DT_Preparer': { role: 'DT_Preparer', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'DT_Viewer': { role: 'DT_Viewer', access: [] },
    'DT_Consultant': { role: 'DT_Consultant', access: [] },
    'IDT_Admin': { role: 'IDT_Admin', access: ['business', 'users', 'businesshierarchy', 'ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'Esign', 'FiletheReturn', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'GstGSTR2A', 'SavedStatus', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'SaveDataToGSTN3b', 'SavedStatusGSTR3b', 'ChooseGSTR3Files'] },
    'IDT_Preparer': { role: 'IDT_Preparer', access: ['ChooseFiles', 'GSTR1Upload', 'DownloadSampleTemplate', 'View all invoices with error / warning', 'Refresh', 'SelectCriteria', 'SelectFromDate', 'AddButton', 'Search', 'NewInvoice', 'DeleteSelectedInv', 'DeleteAllSelectedInv', 'Approve', 'Reject', 'D_all_err_warn', 'ReconcilationSearch', 'ReconcilationDownload', 'AcceptSupplierData', 'AcceptPurchaserData', 'Pending', 'Email', 'AllActions', 'View_Invoices_Err_Pend_appr', 'CreateGSTR1', 'DiscardtheDraft', 'SendforReview', 'CompareSummary', 'Save', 'Submit', 'FetchSummary', 'ChooseGSTR2Files', 'GSTR2Upload', 'SavedStatus', 'GstGSTR2A', 'GSTR3Upload', 'CreateGSTR3B', 'DiscardtheDraft3b', 'ChooseGSTR3Files', 'SavedStatusGSTR3b', 'SaveDataToGSTN3b'] },
    'IDT_Viewer': { role: 'IDT_Viewer', access: [] },
}
export const STATE_LIST = [
    {
        "shortCode": "AP",
        "stateName": "ANDHRA PRADESH",
        "stateCode": "37",
        "id": 1
    },
    {
        "shortCode": "AR",
        "stateName": "ARUNACHAL PRADESH",
        "stateCode": "12",
        "id": 2
    },
    {
        "shortCode": "AS",
        "stateName": "ASSAM",
        "stateCode": "18",
        "id": 3
    },
    {
        "shortCode": "BR",
        "stateName": "BIHAR",
        "stateCode": "10",
        "id": 4
    },
    {
        "shortCode": "CG",
        "stateName": "CHHATTISGARH",
        "stateCode": "22",
        "id": 5
    },
    {
        "shortCode": "GA",
        "stateName": "GOA",
        "stateCode": "30",
        "id": 6
    },
    {
        "shortCode": "GJ",
        "stateName": "GUJARAT",
        "stateCode": "24",
        "id": 7
    },
    {
        "shortCode": "HR",
        "stateName": "HARYANA",
        "stateCode": "06",
        "id": 8
    },
    {
        "shortCode": "HP",
        "stateName": "HIMACHAL PRADESH",
        "stateCode": "02",
        "id": 9
    },
    {
        "shortCode": "JK",
        "stateName": "JAMMU AND KASHMIR",
        "stateCode": "01",
        "id": 10
    },
    {
        "shortCode": "JH",
        "stateName": "JHARKHAND",
        "stateCode": "20",
        "id": 11
    },
    {
        "shortCode": "KA",
        "stateName": "KARNATAKA",
        "stateCode": "29",
        "id": 12
    },
    {
        "shortCode": "KL",
        "stateName": "KERALA",
        "stateCode": "32",
        "id": 13
    },
    {
        "shortCode": "MP",
        "stateName": "MADHYA PRADESH",
        "stateCode": "23",
        "id": 14
    },
    {
        "shortCode": "MH",
        "stateName": "MAHARASHTRA",
        "stateCode": "27",
        "id": 15
    },
    {
        "shortCode": "MN",
        "stateName": "MANIPUR",
        "stateCode": "14",
        "id": 16
    },
    {
        "shortCode": "ML",
        "stateName": "MEGHALAYA",
        "stateCode": "17",
        "id": 17
    },
    {
        "shortCode": "MZ",
        "stateName": "MIZORAM",
        "stateCode": "15",
        "id": 18
    },
    {
        "shortCode": "NL",
        "stateName": "NAGALAND",
        "stateCode": "13",
        "id": 19
    },
    {
        "shortCode": "OR",
        "stateName": "ORISSA",
        "stateCode": "21",
        "id": 20
    },
    {
        "shortCode": "PB",
        "stateName": "PUNJAB",
        "stateCode": "03",
        "id": 21
    },
    {
        "shortCode": "RJ",
        "stateName": "RAJASTHAN",
        "stateCode": "08",
        "id": 22
    },
    {
        "shortCode": "SK",
        "stateName": "SIKKIM",
        "stateCode": "11",
        "id": 23
    },
    {
        "shortCode": "TN",
        "stateName": "TAMIL NADU",
        "stateCode": "33",
        "id": 24
    },
    {
        "shortCode": "TS",
        "stateName": "TELANGANA",
        "stateCode": "36",
        "id": 25
    },
    {
        "shortCode": "TR",
        "stateName": "TRIPURA",
        "stateCode": "16",
        "id": 26
    },
    {
        "shortCode": "UA",
        "stateName": "UTTARAKHAND",
        "stateCode": "05",
        "id": 27
    },
    {
        "shortCode": "UP",
        "stateName": "UTTAR PRADESH",
        "stateCode": "09",
        "id": 28
    },
    {
        "shortCode": "WB",
        "stateName": "WEST BENGAL",
        "stateCode": "19",
        "id": 29
    },
    {
        "shortCode": "AN",
        "stateName": "ANDAMAN AND NICOBAR ISLANDS",
        "stateCode": "35",
        "id": 30
    },
    {
        "shortCode": "CH",
        "stateName": "CHANDIGARH",
        "stateCode": "04",
        "id": 31
    },
    {
        "shortCode": "DN",
        "stateName": "DADRA AND NAGAR HAVELI",
        "stateCode": "26",
        "id": 32
    },
    {
        "shortCode": "DD",
        "stateName": "DAMAN AND DIU",
        "stateCode": "25",
        "id": 33
    },
    {
        "shortCode": "DL",
        "stateName": "DELHI",
        "stateCode": "07",
        "id": 34
    },
    {
        "shortCode": "LD",
        "stateName": "LAKSHADWEEP",
        "stateCode": "31",
        "id": 35
    },
    {
        "shortCode": "PY",
        "stateName": "PUDUCHERRY",
        "stateCode": "34",
        "id": 36
    },
    {
        "shortCode": "LA",
        "stateName": "LADAKH",
        "stateCode": "38",
        "id": 37
    },
    {
        "shortCode": "OT",
        "stateName": "OTHER TERRITORY",
        "stateCode": "97",
        "id": 1020
    },
    // {
    //     "shortCode": "PY",
    //     "stateName": "PONDICHERRY",
    //     "stateCode": "99",
    //     "id": 1022
    // },
    {
        "shortCode": "FC",
        "stateName": "Foreign COUNTRY",
        "stateCode": "96",
        "id": 1021
    },
    {
        "shortCode": "",
        "stateName": "CENTRALISE",
        "stateCode": "00",
        "id": 1023
    }

]

export const PREGST_ISSUE_DROPDOWN = [
    {
        group: 'Central Excise - Taxability',
        items: [
            { id: 1, name: 'Any other process amounting to manufacture or not' },
            { id: 2, name: 'Applicability of Central Excise Exemption' },
            { id: 3, name: 'Clandestine removal of goods' },
            { id: 4, name: 'Classification of Goods under the Central Excise Tariff' },
            { id: 5, name: 'Levy of tax on as such clearance' },
            { id: 6, name: 'Levy of tax on Intermediate Products' },
            { id: 7, name: 'Liquidated Damages' },
            { id: 8, name: 'Packing and Repacking amounts to manufacture or not' },
            { id: 9, name: 'Remission of duty' },
            { id: 10, name: 'Valuation for goods cleared to Related parties' },
            { id: 11, name: 'Value of goods supplied by customer to be added in assessable value or not' },
            { id: 12, name: 'Warranty Supplies' },
        ]
    },
    {
        group: 'Central Excise - Valuation',
        items: [
            { id: 13, name: 'Post Sale discounts' },
            { id: 14, name: 'Valuation of Goods cleared from factory' },
            { id: 15, name: 'Valuation of Goods cleared rom warehouse' },
            { id: 16, name: 'Valuation of Goods non inclusion of specific charges' },
            { id: 17, name: 'Valuation for Goods falling under MRP valuation' }
        ]
    },
    {
        group: 'Central Excise - Registration',
        items: [
            { id: 18, name: 'Validity of Central Excise Manufacturer Registration' },
            { id: 19, name: 'Validity of Central Excise Dealer Registration' }

        ]
    },
    {
        group: 'Central Excise - Documentation',
        items: [
            { id: 20, name: 'Goods not returning from Job Work premises after 1 or 3 years' },
            { id: 21, name: 'Loss/Scrap at Job Work premises' },
            { id: 22, name: 'Rejection of rebate of Central Excise Duty claimed' },
            { id: 23, name: 'Rejection of refund of Central Excise Duty' },
            { id: 24, name: 'Rejection of refund of Cess' },
            { id: 25, name: 'Submission of Merchant Export documents' },
            { id: 26, name: 'Submission of proof of Exports' }
        ]
    },
    {
        group: 'Central Excise - Return Scrutiny',
        items: [
            { id: 27, name: 'ER-1 vs. ER-4 Returns' },
            { id: 28, name: 'Delayed Filing of Return - Penalty' },
            { id: 29, name: 'Delayed Filing of Return - Interest' },
        ]
    },
    {
        group: 'Central Excise - CENVAT Credit',
        items: [
            { id: 30, name: 'Disallowance of CENVAT credit' },
            { id: 31, name: 'Eligibility of CENVAT Credit based on documentation as per Rule 9' },
            { id: 32, name: 'Eligibility of Input, Input Service and Capital Goods Credit' },
            { id: 33, name: 'Rejection of refund of CENVAT Credit claimed under Rule 5' },
            { id: 34, name: 'Reversal of CENVAT Credit when payment is not made within 180 days' },
            { id: 35, name: 'Reversal of Credit as per Rule 6 of CENVAT Credit Rules, 2004' },
            { id: 36, name: 'Time barred credits' }
        ]
    },
    {
        group: 'Service Tax - Taxability',
        items: [
            { id: 37, name: 'Canteen recovery' },
            { id: 38, name: 'Classification of services under Service Tax' },
            { id: 39, name: 'Commission received from overseas customer' },
            { id: 40, name: 'Composition/abatement scheme' },
            { id: 41, name: 'Determination of Place of Supply of Services' },
            { id: 42, name: 'Disallowance of exemption claimed' },
            { id: 43, name: 'Export of Service' },
            { id: 44, name: 'ISD distribution' },
            { id: 45, name: 'Issuance of credit notes' },
            { id: 46, name: 'Non-payment/ Short payment of Service Tax' },
            { id: 47, name: 'Non-payment/ Short payment of Service Tax on advances' },
            { id: 104, name: 'Non-Reversal of ITC under Rule 6 of CCR, 2004' },
            { id: 105, name: 'Non-payment of ST on subvention income collected from dealers/ manufacturers' },
            { id: 106, name: 'Non-payment of ST on penal interest collected from customers' },
            { id: 107, name: 'Non-payment of ST on foreclosure charges and seizure charges collected from customers' },
            { id: 108, name: 'Non-payment of ST on the amount retained towards Risk Pool and delinquencies from dealers' },
            { id: 48, name: 'Notice pay recovery' },
            { id: 49, name: 'Other Employee recovery' },
            { id: 50, name: 'Other intermediary services' },
            { id: 51, name: 'Profit share from overseas customers' },
            { id: 52, name: 'Rejection of Refund of Service Tax' },
            { id: 53, name: 'Service Tax to be paid under RCM' },
            { id: 54, name: 'Time of supply issues' },
            { id: 55, name: 'Valuation for Supply of Services' },
            { id: 56, name: 'Whether RCM is payable or not' },
            { id: 57, name: 'Taxability of Services rendered' },
            { id: 58, name: 'CENVAT Credit Dispute' }

        ]
    },
    {
        group: 'Service Tax - Return Scrutiny',
        items: [
            { id: 59, name: 'ITR vs. ST-3 Returns' }

        ]
    },
    {
        group: 'Service Tax - Valuation',
        items: [
            { id: 60, name: 'Joint Development Agreement' },
            { id: 61, name: 'Reimbursements in the nature of pure agent recovery' },
            { id: 62, name: 'Valuation for Import of Services' },
            { id: 63, name: 'Valuation for related party transactions' },
        ]
    },
    {
        group: 'VAT/CST',
        items: [
            { id: 64, name: 'Stock transfer on sales promotion material' },
            { id: 65, name: 'Submission of all declaration forms' },
            { id: 66, name: 'Submission of C Form' },
            { id: 67, name: 'Submission of E-1 Form' },
            { id: 68, name: 'Submission of F Form' },
            { id: 69, name: 'Submission of H Form' },
            { id: 70, name: 'Submission of I Form' },
            { id: 71, name: 'Submissions/documents misplaced by the department' },
            { id: 72, name: 'Taxability of Outward Movement of Goods ' },
            { id: 73, name: 'Taxability of Works Contract' },
            { id: 74, name: 'Error in CST Order' },
            { id: 75, name: 'ITC disallowed' },
            { id: 76, name: 'Sale in transit' },
            { id: 77, name: 'Sale in course of Import/High sea sales' },
            { id: 78, name: 'Labour and like charges disallowed' },
            { id: 79, name: 'Subcontractor Turnover' },
            { id: 80, name: 'WCT TDS' },
            { id: 101, name: 'VAT paid on consumption' },
            { id: 102, name: 'Error in VAT order' },
            { id: 81, name: 'ONGC Offshore services disallowed' },
            { id: 82, name: 'Rate classification' },
            { id: 83, name: 'Arbitrary Enhancement of turnover' },
            { id: 84, name: 'Deemed sales (RMC)' },
            { id: 85, name: 'SEZ Turnover' },
            { id: 86, name: 'RMC freight and pumping' },
            { id: 87, name: 'Clients Material' },
            { id: 88, name: 'Entry Tax credit disallowed' },
            { id: 89, name: 'Project specific disallowance' },
            { id: 103, name: 'Export Sales/Services' },
            { id: 90, name: 'Any other miscellaneous disallowance' },
            { id: 109, name: 'CST Demand – Due to Additional Demand raise for VAT Issue' },


        ]
    },
    {
        group: 'Excise Duty -Taxability',
        items: [
            { id: 91, name: 'Applicability of Excise duty' },
            { id: 92, name: 'CENVAT Credit Dispute' },
        ]
    },

    {
        group: 'Customs',
        items: [
            { id: 93, name: 'Customs Duty on Technical Know-How Fees' },
            { id: 94, name: 'Difference in Customs Duty on the Raw Material transfer from DTA to EOU unit' },
            { id: 95, name: 'Difference in Customs Duty on debonding of EOU Period' },
            { id: 96, name: 'Customs Duty on end use material transfer from DTA for EOU Production' },
            { id: 97, name: 'Customs Duty on Raw material for manufacturing' },
            { id: 98, name: 'Classification of goods under customs' },
            { id: 99, name: 'Customs Duty on wrong usage of material' },
            { id: 100, name: 'Customs Redemption Fine' },
        ]
    },
    {
        group: 'Others',
        items: [
            { id: 110, name: 'Interest' },
            { id: 111, name: 'Penalty' },
            { id: 112, name: 'Entertainment Tax on convenience fees online bookings' },
            { id: 113, name: 'E-Tax collected but not deposited' },
            { id: 114, name: 'Entertainment Tax on convenience fees on such online bookings' },
            { id: 115, name: 'Entertainment tax on regional movie' },
            { id: 116, name: 'Seating Capacity is less than the prescribed limit for availing exemption benefits' },
            { id: 118, name: 'Entertainment Tax - Goa (Ownership and Single Screen)' },
            { id: 119, name: 'E Tax on Convenience Fees' },
            { id: 120, name: 'E-Tax amount has been shown in Ticket' },
            { id: 121, name: 'Show tax increased - Municipal Corporation' },
            { id: 122, name: 'E.P.F - issue' },
            { id: 123, name: 'Non-payment of stamp duty on Leave & License Agreement' },
            { id: 124, name: 'Claims against the Company not acknowledged as debt' },
            { id: 125, name: 'Additional cost towards electricity due to increase in Tariff' },
            { id: 126, name: 'Arbitration Petiti' },

        ]
    },

];

//  GST & Audit Issue List
export const ISSUE_GST = [
    {
        group: 'Return scrutiny ',
        items: [
            { id: 1, name: 'GSTR2A vs GSTR3B' },
            { id: 2, name: 'GSTR1 vs GSTR3B' },
            { id: 3, name: 'E-invoice vs GSTR1' },
            { id: 4, name: 'E-way bill vs GSTR1' },
            { id: 5, name: 'GSTR 9 vs GSTR 1/3B' },
            { id: 6, name: 'GSTR2B vs GSTR3B' },
            { id: 106, name: 'GSTR7 vs GSTR 3B' },
            { id: 109, name: 'GSTR7 vs GSTR 9B' },
            { id: 115, name: 'GSTR7 vs GSTR1 vs GSTR3B' },
            { id: 138, name: 'GSTR 3B late filed' }
        ]
    },
    {
        group: 'Procedural ',
        items: [
            { id: 11, name: 'TDS/TCS issues' },
            { id: 12, name: 'E invoice issues' },
            { id: 13, name: 'Eway bill issues' }
        ]
    },
    {
        group: 'Classification/Rate ',
        items: [
            { id: 21, name: 'HSN classification' },
            { id: 22, name: 'SAC classification' },
            { id: 23, name: 'Composite Supply vs Mixed Supply' },
            { id: 24, name: 'Goods vs Service' },
            { id: 24, name: 'Taxability of Riders in Life Insurance' },
            { id: 25, name: 'Permanent transfer of Intellectual property' },
        ]
    },
    {
        group: 'Taxability',
        items: [
            { id: 31, name: 'Warranty supplies' },
            { id: 32, name: 'Liquidated Damages' },
            { id: 33, name: 'Notice Pay Recovery' },
            { id: 34, name: 'Toleration of an act' },
            { id: 34, name: 'Works Contract' },
            { id: 35, name: 'Canteen recoveries from employees' },
            { id: 36, name: 'Other recoveries from employees' },
            { id: 37, name: 'Carry income' },
            { id: 38, name: 'Commission received from overseas customer' },
            { id: 39, name: 'Profit share from overseas customers' },
            { id: 40, name: 'Other intermediary services' },
            { id: 41, name: 'Transfer of leasehold rights' },
            { id: 42, name: 'Outward FOC supplies' },
            { id: 43, name: 'Stock transfer on sales promotion material' },
            { id: 44, name: 'Donation/Grants received' },
            { id: 45, name: 'Supply to ship/vessels' },
            { id: 46, name: 'Interest on delayed receipt of consideration from customers' },
            { id: 47, name: 'Transfer of business as a going concern' },
            { id: 48, name: 'Sale of re possessed assets under SARFAESI Act' },
            { id: 131, name: 'GST not paid on advance receipts from customer' },
            { id: 132, name: 'Interest not deposited on reversal of ITC for payment made after 180 days' },
            { id: 142, name: ' Disallowance of Sales returns' },
            { id: 147, name: 'Interest on wrong availment of ITC' }
        ]
    },
    {
        group: 'Liability',
        items: [
            { id: 49, name: 'Interest on delayed payment of tax' },
            { id: 50, name: 'Time of supply issues' },
            { id: 51, name: 'ISD distribution' },
            { id: 52, name: 'Issuance of credit notes' },
            { id: 53, name: 'Adjustment of Credit notes' },
            { id: 105, name: 'GST on Cross Charge' },
            { id: 141, name: 'Short payment of GST' }
        ]
    },
    {
        group: 'Valuation ',
        items: [
            { id: 54, name: 'Related parties' },
            { id: 55, name: 'Corporate guarantees' },
            { id: 56, name: 'Post Sale discounts' },
            { id: 57, name: 'Joint Development Agreement' },
            { id: 58, name: 'Reimbursements in the nature of pure agent recovery' },
            { id: 59, name: 'Valuation on Cross charge transactions' },
            { id: 60, name: 'Brand and license usage given to subsidiary companies' }
        ]
    },
    {
        group: 'ITC ',
        items: [
            { id: 61, name: 'Motor Vehicles for Transportation of Passengers (Less than 13 Pax)' },
            { id: 62, name: 'General Insurance, Servicing, Repair & Maintenance of above' },
            { id: 63, name: 'Food & Beverages including Outdoor Catering' },
            { id: 64, name: 'Leasing, renting or hiring of motor vehicles for Passenger Transportation' },
            { id: 65, name: 'Life Insurance' },
            { id: 66, name: 'Health Insurance' },
            { id: 67, name: 'Works Contract Services (or) Goods/Services used in Construction – to the extent of Capitalization' },
            { id: 68, name: 'Goods lost, stolen, destroyed, written off or disposed of by way of gift or free samples' },
            { id: 69, name: 'Beauty treatment, Health services, Cosmetic and Plastic surgery' },
            { id: 70, name: 'Membership of a Club, Health and Fitness Centre' },
            { id: 71, name: 'Travel benefits to employees on vacation such as leave or home travel concession' },
            { id: 72, name: 'Goods or Services or both used for personal consumption.' },
            { id: 73, name: 'Time barred Credits' },
            { id: 74, name: 'Common Credit Reversals' },
            { id: 75, name: 'ITC reversal if payment is not made within 180 days' },
            { id: 76, name: 'Rule 36(4) reversals' },
            { id: 77, name: 'Ineligible credit - ISD' },
            { id: 78, name: 'ITC on promotional goods' },
            { id: 79, name: 'CSR credit' },
            { id: 81, name: 'Residential colony' },
            { id: 82, name: 'Canteen credit' },
            { id: 83, name: 'ITC on reversal of securities' },
            { id: 84, name: 'Excess claim of ITC' },
            { id: 102, name: 'Wrong ITC availed' },
            { id: 103, name: 'Credit availed on Xerox invoices' },
            { id: 104, name: 'Credit availed Without invoices' },
            { id: 107, name: 'Ineligible ITC' },
            { id: 108, name: 'Defaulter vendors ITC' },
            { id: 116, name: 'ITC Disallowed - GSTR3B not filed by supplier' },
            { id: 128, name: 'ITC Disallowed - GSTR3B filed with Nil Turnover' },
            { id: 129, name: 'ITC Disallowed' },
            { id: 136, name: 'Reversal of ITC on credit notes issued by vendors' },
            { id: 143, name: 'Ineligible ITC which pertains to exempted sales' },
            { id: 144, name: 'ITC availed is not involved in the furtherance of the business as per Sec.16(1)' },
            { id: 145, name: 'Non reversal of ITC on cancelation of supplier registration retrospectively' },
            { id: 146, name: 'Non reversal of alleged block ITC claimed on inward supply' },
            { id: 148, name: 'Delay filing of GSTR-1 by Supplier' }
        ]
    },
    {
        group: 'Transition ',
        items: [
            { id: 84, name: 'Capital goods in transit' },
            { id: 85, name: 'Trading inventory' },
            { id: 86, name: 'Edu cess'},
            { id: 87, name: 'Service invoice accounted after 30th June' },
            { id: 88, name: 'Refund under Sec 142(3)' },
            { id: 89, name: 'VAT C form issue' },
            { id: 91, name: 'Entry Tax transition issue' },
            { id: 92, name: 'Mismatch of Earlier returns vs TRAN - 1' },
            { id: 93, name: 'Pre GST inventory written off' },
            { id: 94, name: 'Reversal of excess ITC claimed in Trans -1' },
            { id: 95, name: 'Interest on reversal of excess ITC claimed in Trans -1' },
            { id: 80, name: 'WCT TDS' },
            { id: 122, name: 'VAT paid on consumption' },
            { id: 123, name: 'Excise Credit on Closing Stock (Inputs)' },
            { id: 124, name: 'Excise on inputs received after 01/07/2017' },
            { id: 125, name: 'ITC of Exempt projects (pre-GST)' },
            { id: 126, name: 'Service Tax on unadjusted advance' },
            { id: 127, name: 'Cenvat Credit Balance Carry forward' },
            { id: 139, name: 'Excess/Double availment of ITC on Capital Goods' }

        ]
    },
    {
        group: 'Job Work   ',
        items: [
            { id: 94, name: 'Goods not returning from JW premises after 1/3 years' },
            { id: 95, name: 'Loss/Scrap at JW premises' },
            { id: 96, name: 'ITC - 04 vs JW register' }
        ]
    },
    {
        group: 'RCM',
        items: [
            { id: 97, name: 'RCM not paid' },
        ]
    },
    {
        group: 'Refunds',
        items: [
            { id: 98, name: 'Rule 96A - Export of goods not being done within 105 days post issuance of invoice' },
            { id: 99, name: 'Rule 96B - Export refund restriction FEMA' },
            { id: 100, name: 'Rule 96(10) - Merchant export , Advance license' },
            { id: 101, name: 'Time limit/Limitation issues' },
            { id: 114, name: 'Refund of Excess Payment of Tax' },
            { id: 119, name: 'Inverted Duty Structure (IDS) Refund Issue' },
            { id: 120, name: 'Interest on delayed refund' },
            { id: 130, name: 'GST TDS refund from Electronic Cash Ledger' }

        ]
    },
    {
        group: 'Others',
        items: [
            { id: 111, name: 'GSTR 3B Non Filers' },
            { id: 112, name: 'RC Cancelled Suppliers' },
            { id: 113, name: 'Concessional Supplies' },
            { id: 117, name: 'Denial of Exemption' },
            { id: 118, name: 'GSTR 3B filed with Nil Turnover' },
            { id: 121, name: 'Interest on delayed credit of refund' },
            { id: 133, name: 'Detention on account of incomplete documentation' },
            { id: 134, name: 'Separate Account books not maintained for GSTIN' },
            { id: 135, name: 'Details of transactions reported in Table 5O of GSTR9C' },
            { id: 137, name: 'Fake Invoice Issue' },
            { id: 140, name: 'Other Miscellaneous' },
            { id: 146, name: 'Short declaration of turnover' },
            { id: 147, name: 'Short payment of Output taxes on supply of electricity' },
            { id: 148, name: 'Short payment of taxes on freight and insurance services' },
        ]
    },
];


export const AUDITDESIGNATION = [
    { key: 'Superintendent', value: 'Superintendent' },
    { key: 'Inspector', value: 'Inspector' },
    { key: 'Intelligence Officer', value: 'Intelligence Officer' },
    { key: 'Preventive Officer', value: 'Preventive Officer' },
    { key: 'State Tax Officer', value: 'State Tax Officer' },
    { key: 'Appraiser', value: 'Appraiser ' },
    { key: 'Joint Director', value: 'Joint Director' },
    { key: 'Deputy Director', value: 'Deputy Director' },
    { key: 'Examiner', value: 'Examiner' },
    { key: 'Assistant Commissioner', value: 'Assistant Commissioner' },
    { key: 'Additional Commissioner', value: 'Additional Commissioner' },
    { key: 'Deputy Commissioner', value: 'Deputy Commissioner' },
    { key: 'Joint Commissioner', value: 'Joint Commissioner' },
    { key: 'Commissioner', value: 'Commissioner' },
    { key: 'Principal Commissioner', value: 'Principal Commissioner' },
    { key: 'Directorate General', value: 'Directorate General' },
    { key: 'Other', value: 'Other' }

];


export const TYPE_OF_AUTHORITY = [
    { key: "CGST", value: "CGST" },
    { key: "SGST", value: "SGST" },
    { key: "DGCEI", value: "DGCEI" },
    { key: "Anti-Evasion", value: "Anti-Evasion" },
    { key: "Preventive", value: "Preventive" },
    { key: "Others", value: "Others" },
]

export const DESIGNATION_ADJ = [
    { key: 'Additional Commissioner', value: 'Additional Commissioner' },
    { key: 'Assistant Commissioner', value:'Assistant Commissioner' },
    { key: 'Deputy Commissioner', value:'Deputy Commissioner' },
    { key: 'Joint Commissioner', value: 'Joint Commissioner' },
    { key: 'Commissioner', value: 'Commissioner' },
    { key: 'Principal Commissioner', value: 'Principal Commissioner' },
    { key: 'Superintendent', value: 'Superintendent' },
    { key: 'Inspector', value: 'Inspector' },
    { key: 'Intelligence Officer', value: 'Intelligence Officer' },
    { key: 'Preventive Officer', value: 'Preventive Officer' },
    { key: 'State Tax Officer', value: 'State Tax Officer' },
    { key: 'Joint Director', value: 'Joint Director' },
    { key: 'Deputy Director', value: 'Deputy Director' },
    { key: 'Examiner', value: 'Examiner' },
]
export const DESIGNATION = [
    { key: 'Superintendent', value: 'Superintendent' },
    { key: 'Inspector', value: 'Inspector' },
    { key: 'Intelligence Officer', value: 'Intelligence Officer' },
    { key: 'Preventive Officer', value: 'Preventive Officer' },
    { key: 'State Tax Officer', value: 'State Tax Officer' },
    { key: 'Joint Director', value: 'Joint Director' },
    { key: 'Deputy Director', value: 'Deputy Director' },
    { key: 'Assistant Director', value: 'Assistant Director' },
    { key: 'Additional Director', value: 'Additional Director' },
    { key: 'Examiner', value: 'Examiner' },
    { key: 'Additional Commissioner', value: 'Additional Commissioner' },
    { key: 'Assistant Commissioner', value:'Assistant Commissioner' },
    { key: 'Deputy Commissioner', value:'Deputy Commissioner' },
    { key: 'Joint Commissioner', value: 'Joint Commissioner' },
    { key: 'Commissioner', value: 'Commissioner' },
    { key: 'Principal Commissioner', value: 'Principal Commissioner' },
    { key: 'Senior Intelligence Officer', value: 'Senior Intelligence Officer' },
]
export const DESIGNATION_COMMISSIONER = [
    { key: 'Commissioner', value: 'COMMISSIONER' },
    { key: 'Joint Commissioner', value: 'JOINT COMMISSIONER' },
    { key: 'Additional Commissioner', value: 'ADDITIONAL COMMISSIONER' },
    { key: 'Deputy Commissioner', value:'DEPUTY COMMISSIONER' },
]

export const DESIGNATION_COMMISSIONER_GST = [
    { key: 'Commissioner', value: 'COMMISSIONER' },
    { key: 'Joint Commissioner', value: 'JOINT COMMISSIONER' },
    { key: 'Additional Commissioner', value: 'ADDITIONAL COMMISSIONER' },
    { key: 'Deputy Commissioner', value:'DEPUTY COMMISSIONER' },
    { key: 'Principal Commissioner', value: 'PRINCIPAL COMMISSIONER' },

]
export const FORM_OF_PAYMENT = [
    { key: 'DR03', value: 'DR03' }
];
export const MODE_OF_PAYMENT = [
    { key: 'NEFT', value: 'NEFT' },
    { key: 'RTGS', value: 'RTGS' },
    { key: 'Cheque', value: 'Cheque' },
    { key: 'Amount Adjusted For Other Demand', value: 'Amount Adjusted For Other Demand' },
    { key: 'Electronic Credit Ledger', value: 'Electronic Credit Ledger' },
    { key: 'Electronic Cash Ledger ', value: 'Electronic Cash Ledger ' },

];

export const MODE_OF_PAYMENT_LIABILITY_ADMITTED = [
    { key: 'Cash', value: 'Cash' },
    { key: 'Credit', value: 'Credit' },
];

export const FORUM_APPEAL = [
    { key: "preapa", value: " Commissioner Appeal" },
    { key: "preapt", value: "Appellate Tribunal" },
    { key: "prehc", value: "High Court" },
    { key: "presc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" },
]

export const FORUM_APPEAL_GST = [
    { key: "apa", value: " Commissioner Appeal" },
    { key: "apt", value: "Appellate Tribunal" },
    { key: "hc", value: "High Court" },
    { key: "sc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" },
]
export const FORUM = [
    { key: "Pre-Litigation", value: "scn" },
    { key: "Adjudication", value: "aja" },
    { key: "Commissioner(A)", value: "apa" },
    { key: "Appellate Tribunal", value: "apt" },
    { key: "High Court", value: "hc" },
    { key: "Supreme Court", value: "sc" },
]

export const FORUM_APPEAL_COMMISONER_ALL_OPTIONS = [
    { key: "apt", value: "Appellate Tribunal" },
    { key: "hc", value: "High Court" },
    { key: "sc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" },
]


export const FORUM_APPEAL_COMMISSIONER = [
    { key: "preapt", value: "Appellate Tribunal" },
    { key: "prehc", value: "High Court" },
    { key: "presc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" }
]

export const FORUM_APPEAL_APPELLATE = [
    { key: "prehc", value: "High Court" },
    { key: "presc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" }
]

export const FORUM_HIGHCOURT_APPELLATE = [
    { key: "presc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" }
]

export const APPLICATION_STATUS = [
    { key: 'Pending for Hearing', value: 'Pending for Hearing' },
    { key: 'Stay Rejected', value: 'Stay Rejected' },
    { key: 'Stay Granted', value: 'Stay Granted' }
]
export const TYPE_OF_STAY = [
    { key: 'Full Stay', value: 'Full Stay' },
    { key: 'Partial Stay', value: 'Partial Stay' },
    { key: 'Conditional Stay', value: 'Conditional Stay' }
]

export const FORM_TYPE_DETAILS_OF_APPEAL = [
    { key: 'Prov. Ack. for APL-01', value: 'Prov. Ack. for APL-01' },
    { key: 'APL-02', value: 'APL-02' },
    { key: 'Ack. for APL-03', value: 'Ack. for APL-03' },
    { key: 'Ack. for APL-05', value: 'Ack. for APL-05' },
    { key: 'Ack. for APL-06', value: 'Ack. for APL-06' },
    { key: 'APL-07', value: 'APL-07' },
    { key: 'Ack. for APL-08', value: 'Ack. for APL-08' },
    { key: 'Others', value: 'Others' },

]

export const FORUM_APPEAL_APPELLATE_TRIBUNAL = [
    // { key: "hc", value: "High Court "},
    { key: "sc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" },

]

export const FORUM_APPEAL_APPELLATE_TRIBUNAL_1 = [
    { key: "hc", value: "High Court" },
    { key: "sc", value: "Supreme Court" },
    { key: "Appeal/Writ", value: "Appeal/Writ not Filed" },
]

export const CASE_TYPES = {
    NEW: 'NEW',
    WRIT: 'WRIT',
    MEMORANDUM: 'MEMORANDUM',
    REMANDED: "REMANDED"
}
export const TYPE_OF_CASE = {
    NEW: 'NEW',
    PENALTY: 'PENALTY',
    MEMORANDUM: 'MEMORANDUM',
    REMANDED: "REMANDED"
}
export const DEMAND_AMT_OPTIONS = [
    { key: "gt", value: "Greater Than" },
    { key: "lt", value: "Less Than" },
    { key: "eq", value: "Equal to" },
    { key: "gte", value: "Greater Than Equal to" },
    { key: "lte", value: "Less Than Equal to" },

]
export const badgeMapAudit = {
    "auditId": "Audit Id",
    "consultant": "Consultant",
    "div": "Divison",
    "gstinDiv": "Divison",
    "personResponsible": "Person Responsible",
    "gstin": "Registration Number",
    "state": "State",

}
export const badgeMapInvoice = {
    "act": 'Act',
    "actType": 'Act',
    "caseId": "Case Id",
    "auditId": "Audit Id",
    "caseLabel": "Case Label",
    "consultant1": "Consultant",
    "consultant": "Consultant",
    "amtQueryValue": "Demand Amount",
    "totOfTotal1": "Demand Amount",
    "div": "Divison",
    "gstinDiv": "Divison",
    "dd1": "Due Date",
    "dueDateOfReply": "Due Date",
    "dueDate": "Due Date",
    "issue": "Issue",
    "issues.issue": "Issue",
    "issues.keyword": "Key Word",
    "keyword": "Key Word",
    "kw": "Key word",
    "pf1": "Period From",
    "periodFromDate": "Period From",
    "pt1": "Period To",
    "periodToDate": "Period To",
    "personResponsible1": "Person Responsible",
    "personResp": "Person Responsible",
    "regNo": "Registration Number",
    "gstin": "Registration Number",
    "st": "Status",
    "status": "Status",
    "state": "State",
    "ay": "Assessment Year"

}

export const REGNO = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
]
export const INVITED_HEIRARCHY = [
    { key: "Root PAN Name", value: "Root PAN Name" },
    { key: "Legal PAN Name", value: "Legal PAN Name" },
    { key: "GSTIN", value: "GSTIN" }

]

export const ROLE = [
    { key: "Preparer", value: "Preparer" },
    { key: "Admin", value: "Admin" },
    { key: "Viewer", value: "Viewer" }
]

export const STATUS = [
    { key: "Invited", value: "Invited" },
    { key: "Registered", value: "Registered" },
]

export const MODULE = [
    { key: "GST", value: "gstwl" },
    { key: "PRE GST", value: "pregstwl" },
    { key: "Audit", value: "auditwl" }
]

export const ACT_NAME = [
    { key: "Excise", value: "EXCISE" },
    { key: "Customs", value: "CUSTOMS DUTY" },
    { key: "VAT", value: "VAT" },
    { key: "Service Tax", value: "SERVICE TAX" },
]

export const CASE_LEVEL = [
    { key: 'Drop down all case ids with one option to select all and select single or multiple', value: 'Drop down all case ids with one option to select all and select single or multiple' },
    { key: 'If all selected - show all option', value: 'If all selected - show all option' },
    { key: 'If multiple selected, show the selected case IDs', value: 'If multiple selected, show the selected case IDs' },
    { key: 'If single case id selcted, Show the selcted case ID', value: 'If single case id selcted, Show the selcted case ID' }


]
// DT modal
export const ORDER_PASSED_BY = [
    { label: 'CPC', value: "CPC" },
    { label: 'Income Tax Officer', value: "Income Tax Officer" },
    { label: 'Assistant Commissioner', value: "Assistant Commissioner" },
    { label: 'Deputy Commissioner', value: "Deputy Commissioner" },
    { label: 'Joint Commissioner', value: "Joint Commissioner" },
    { label: 'Additional Commissioner', value: "Additional Commissioner" },
    { label: 'Commissioner', value: "Commissioner" },
    { label: 'Assessing Officer', value: "Assessing Officer" },
]

export const NOTICE_ISSUED_US = [
    { label: 'U/s 148', value: "U/s 148" },
    { label: 'U/s 148A(b)', value: "U/s 148A(b)" },
    { label: 'U/s 143(2)', value: "U/s 143(2)" },
    { label: 'U/s 142(1)', value: "U/s 142(1)" },
    { label: 'U/s 142ADVO', value: "U/s 142ADVO" },
    { label: 'U/s 92CA (2) TPO', value: "U/s 92CA (2) TPO" },
    { label: 'U/s 92D (3) TPO', value: "U/s 92D (3) TPO" },
    { label: 'U/s 201/201(A)', value: "U/s 201/201(A)" },
    { label: 'U/s 263', value: "U/s 263" },
    { label: 'U/s 264', value: "U/s 264" },
    { label: 'U/s 153A', value: "U/s 153A" },
    { label: 'U/s 270A', value: "U/s 270A" },

]
export const ISSUING_AUTHORITY = [
    { label: 'Assessment Unit NFAC', value: "Assessment Unit NFAC" },
    { label: 'Income Tax Officer', value: "Income Tax Officer" },
    { label: 'Assistant Commissioner', value: "Assistant Commissioner" },
    { label: 'ANTI-Deputy Commissioner', value: "ANTI-Deputy Commissioner" },
    { label: 'Joint Commissioner', value: "Joint Commissioner" },
    { label: 'Additional Commissioner', value: "Additional Commissioner" },
    { label: 'Transfer Pricing Officer', value: "Transfer Pricing Officer" },
    { label: 'Departmental Valuation Officer', value: "Departmental Valuation Officer" },
    { label: 'Principal Commissioner', value: "Principal Commissioner" },
    { label: 'Commissioner of Income Tax (A)', value: "Commissioner of Income Tax (A)" },
]
export const TYPE_OF_NOTICE = [
    { label: ' Section 131 - Power regarding discovery, production of evidence, etc.', value: "Section 131 - Power regarding discovery, production of evidence, etc." },
    { label: ' Section 132 -  Search and seizure.', value: "Section 132 -  Search and seizure." },
    { label: ' Section 132A - Powers to requisition books of account, etc.', value: "Section 132A - Powers to requisition books of account, etc." },
    { label: ' Section 132B - Application of seized or requisitioned assets.', value: "Section 132B - Application of seized or requisitioned assets." },
    { label: ' Section 133 - Power to call for information.', value: "Section 133 - Power to call for information." },
    { label: ' Section 133A - Power of survey', value: "Section 133A - Power of survey" },
    { label: ' Section 133B - Power to collect certain information.', value: "Section 133B - Power to collect certain information." },
    { label: ' Section 133C - Power to call for information by prescribed income-tax authority.', value: "Section 133C - Power to call for information by prescribed income-tax authority." },
    { label: ' Section 134 - Power to inspect registers of companies.', value: "Section 134 - Power to inspect registers of companies.." }
]
export const TYPE_NOTICE = [
    { label: 'Notice from', value: "Notice from" },
    { label: 'Valuation officer', value: "Valuation officer" },
]
export const TYPE_OF_NOTICE_OF_ASSESSMENT = [
    { label: 'Notice', value: "Notice" },
    { label: ' Show cause notice', value: "Show cause notice" },
    { label: ' Penalty Notice', value: "Penalty Notice" },
    { label: ' Notice from Valuation officer.', value: "Notice from Valuation officer" },
    { label: ' Notice from Transfer Pricing officer', value: "Notice from Transfer Pricing officer" },
]
export const TYPE_OF_RESPONSE = [
    { label: 'FULL', value: "FULL" },
    { label: 'PART', value: "PART" },
]
export const AUTHORITY_LIST = [
    { key: 'opt1 ', value: "opt1" },
    { key: 'opt2 ', value: "opt2" },
]
export const SUB_MODE = [
    { key: 'mode1 ', value: "mode1" },
    { key: 'mode2 ', value: "mode2" },
]

export const RECTIFICATION_MODE = [
    { key: ' Online Submitted ', value: "Online Submitted" },
    { key: ' Manually submitted ', value: " Manually submitted" },
]
export const RECTIFICATION = [
    { key: ' Reprocess the Return', value: "Reprocess the Return" },
    { key: ' 234C Interest', value: "234C Interest" },
    { key: ' Tax Credit Mismatch ', value: "Tax Credit Mismatch" },
    { key: ' Upload Rectified JSON ', value: "Upload Rectified JSON" },
    { key: ' Manual Rectification ', value: " Manual Rectification" },
    { key: ' Other ', value: "Other" },

]
export const APPLSTATUS_LIST = [
    { key: 'Stay Granted ', value: "Stay Granted" },
    { key: 'Stay Rejected ', value: "Stay Rejected" },
    { key: 'Hearing Due ', value: "Hearing Due" },
]
export const STATUS_DATA = [
    { key: 'Dept. Response Pending ', value: "Dept. Response Pending" },
    { key: 'CLOSED', value: "CLOSED" },
    { key: 'No Response-Closed', value: "No Response-Closed" },
]

export const ACTION_ENQUIRY_PROCEED = [
    { label: 'Dept. Response Pending ', value: "DEPT RESPONSE PENDING" },
    { label: 'CLOSED', value: "CLOSED" },
]

export const ACTION_INTIMATION_ORDER = [
    { label: ' Appeal Preferred', value: "AP" },
    { label: ' Writ Preferred', value: "WP" },
    { label: 'Rectification Preferred', value: "RP" },
    { label: 'CLOSED', value: "CLOSED" },
]
export const ACTION_INTIMATION_ORDER3 = [
    { label: ' Appeal Preferred', value: "AP" },
    { label: ' Writ Preferred', value: "WP" },
    { label: 'Re Rectification Preferred', value: "RRP" },
    { label: 'CLOSED', value: "CLOSED" },
]
export const ACTION_ASSESSMENT_ORDER = [
    { label: ' Appeal Preferred', value: "AP" },
    { label: ' Writ Preferred', value: "WP" },
    { label: 'Pending For Rectified Orderd', value: "PFRO" },
    { label: 'CLOSED', value: "CLOSED" },
]
export const ACTION_COMM_ORDER3 = [
    { label: ' Appeal Preferred', value: "AP" },
    { label: ' Writ Preferred', value: "WP" },
    { label: 'Closed', value: "CLOSED" },
    { label: 'Dept. Tribunal Appeal', value: "DAP" },

]
export const ACTION_HC_ORDER3 = [
    { label: ' Appeal Preferred', value: "AP" },
    //   { label: ' Writ Preferred', value: "WP"},
    { label: 'Closed', value: "CLOSED" },
    { label: 'Dept. Supreme court Appeal', value: "DAP" },

]

export const ACTION_SC_ORDER = [
    //    {label: "Not Applicable", value:"Not Applicable"},
    { label: 'Closed', value: "CLOSED" }
]
export const ACTION_TRIB_ORDER3 = [
    { label: ' Appeal Preferred', value: "AP" },
    { label: ' Writ Preferred', value: "WP" },
    { label: 'Closed', value: "CLOSED" },
    { label: 'Dept. High court Appeal', value: "DAP" },

]
export const FORUM_ENQUIRY_PROCEED = [
    { label: " Commissioner Appeal", value: "dtcommissioner" },
    { label: "High Court", value: "dthc" },
    { label: "CLOSED", value: "CLOSED" },
    { label: "Not Applicable", value: "Not Applicable" }

]
export const INTIMATION_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
    { key: "P", value: "Partly Favorable" },
    { key: "PU", value: "Partly Unfavorable" },
]
// export const FORUM_ENQUIRY_PROCEED = [
//   {key:"dtcommissioner", value:"Commissioner Appeal"},
//   {key:"dthc", value:"High Court"},
//   {key:"Not Applicable", value:"Not Applicable"},

// ]
export const FORUM_COMISSIONER_PROCEED = [
    { key: "dtitat", value: "Tribunal Appeal" },
    { key: "dthc", value: "High Court" },
    { key: 'dtitat', value: "Dept. Tribunal Appeal" },
    { key: "CLOSED", value: "Closed" }
]
export const FORUM_TRIBUNAL_PROCEED = [
    { key: "dthc", value: "High Court Appeal" },
    { key: "dthc", value: "High Court" },
    { key: 'dthc', value: "Dept. High Court Appeal" },
    { key: "CLOSED", value: "Closed" }
]
export const FORUM_HC_PROCEED = [
    { key: "dtsc", value: "Supreme Court Appeal" },
    { key: "dthc", value: "High Court" },
    { key: 'dtsc', value: "Dept. Supreme Court Appeal" },
    { key: "CLOSED", value: "Closed" }
]
export const FORUM_SC_PROCEED = [
    { key: 'Not Applicable', value: "Not Applicable" },
    { key: "CLOSED", value: "Closed" }
]
export const ASSESSMENT_OUTCOME = [
    { key: "F", value: "Favorable" },
    { key: "U", value: "Unfavorable" },
]

export const ISSUE_CONCLUSION_DT = [
    { key: "F", value: "FAVORABLE" },
    { key: "U", value: "UNFAVORABLE" },
    { key: "PF", value: "PARTLY FAVORABLE" },
    { key: "PU", value: "PARTLY UNFAVORABLE" },
    { key: "A", value: "ADMITTED" }
]
export const ISSUE_CONCLUSIONS_DT = [
    { key: "F", value: "FAVORABLE" },
    { key: "U", value: "UNFAVORABLE" },
    { key: "PF", value: "PARTLY FAVORABLE" },
    { key: "PU", value: "PARTLY UNFAVORABLE" },
    { key: "A", value: "ADMITTED" },
    { key: "R", value: "REMANDED BACK" },
    { key: "PR", value: "PARTLY REMANDED BACK" },
]
export const MATTER_TYPE_DT = [
    { key: "low", value: "Question of Law" },
    { key: "fact", value: "Question of Fact" }
]
export const MODE_TYPE = [
    { key: "neft", value: "NEFT" },
    { key: "rtgs", value: "RTGS" },
    { key: "cheque", value: "Cheque" },
    { key: "amtdmd", value: "Amount Adjusted For Other Demand" }


]
export const TYPE_OF_MATTER = [
    { label: ' Question of Law', value: "Question of Law" },
    { label: ' Question of Fact', value: "Question of Fact" },
]
export const TYPE_OF_REASON = [
    { label: 'Taxpayer Appeal', value: "Taxpayer Appeal" },
    { label: 'Department Appeal', value: "Department Appeal" },
]
export const CASE_MOVED = [
    { label: 'Intimation Order', value: "Intimation Order" },
    { label: 'Assessment', value: "Assessment" },
    { label: 'Commissioner (A)', value: "Commissioner (A)" },
]

export const CASE_MOVED_HC = [
    { label: 'Intimation', value: "Intimation Order" },
    { label: 'Assessment', value: "Assessment" },
    { label: 'Commissioner (A)', value: "Commissioner (A)" },
    { label: 'Appellate Tribunal', value: "Appellate Tribunal" },
]

export const CASE_MOVED_SC = [
    { label: 'Intimation', value: "Intimation" },
    { label: 'Assessment', value: "Assessment" },
    { label: 'Commissioner', value: "C(A) " },
    { label: 'Tribunal', value: "Tribunal" },
    { label: 'High Court', value: "High Court" },

]

export const REDUCED1 = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const NOTICE_USER_ACTION = [
    { label: 'Yet to read', value: "Yet to read" },
    { label: 'Read by User', value: "Read by User" },
    // { label: 'Read - Action need to take', value: "Read - Action need to take" },
    // { label: 'Read - No action required', value: "Read - No action required" }
]
export const DT_NM_STATUS = [
    { label: 'Open', value: "O" },
    { label: 'Close', value: "C" },
]
export const DT_NM_RESPONSE = [
    { label: 'Replied', value: "Y" },
    { label: 'Pending', value: "N" },
]
export const DT_NM_AY = [
    { label: '2024 - 2025', value: "2024" },
    { label: '2023 - 2024', value: "2023" },
    { label: '2022 - 2023', value: "2022" },
    { label: '2021 - 2022', value: "2021" },
    { label: '2020 - 2021', value: "2020" },
    { label: '2019 - 2020', value: "2019" },
    { label: '2018 - 2019', value: "2018" },
    { label: '2017 - 2018', value: "2017" },
    { label: '2016 - 2017', value: "2016" },
    { label: '2015 - 2016', value: "2015" },
    { label: '2014 - 2015', value: "2014" },
    { label: '2013 - 2014', value: "2013" },
    { label: '2012 - 2013', value: "2012" },
    { label: '2011 - 2012', value: "2011" },
    { label: '2010 - 2011', value: "2010" },
    { label: '2009 - 2010', value: "2009" },
]
export const NOTICE_TYPE = [
    { key: 'ADJNF', value: "NON-FILERS OF RETURNS" },
    { key: 'ADJGP', value: "PENALTY" },
    { key: 'RCMOR', value: "RECOVERY" },
    { key: 'APPEL', value: "APPEAL" },
    { key: 'RFUND', value: "REFUND" },
    { key: 'ADJSR', value: "SCRUTINY OF RETURNS" },
    { key: 'ADJDT', value: "DETERMINATION OF TAX" },
    { key: 'IETCS', value: "GOODS CONVEYANCE ENF CASE" },
    { key: 'AMYDT', value: "DETERMINATION OF TAX MFY" },
    { key: 'ENCFC', value: "ENFORCEMENT CASE" },
    { key: 'AUDIT', value: "AUDIT" },
    { key: 'RCPGD', value: "PRE - GST RECOVERY" },
    { key: 'ADJRO', value: "RECTIFICATION OF ORDERS" },
    { key: 'TRAN1', value: "TRANSITION FORM 1" },
    { key: 'TRAN2', value: "TRANSITION FORM 2" },
]
export const LOG_ACTION = [
    { label: 'Update', value: "UPDATE" },
    { label: 'Create', value: "CREATE" },
    { label: 'Delete', value: "DELETE" },
]
export const GRANTED = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const ITR_DETAILS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

export const WRIT_TO_HC = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]
export const ORDER_RECEIVED = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
]

export const TYPE_OF_ODRDER = [
    { key: 'U/s 143(3)', value: "U/s 143(3)" },
    { key: 'U/s 154 ', value: "U/s 154" },
    { key: 'U/s 143(1)', value: "U/s 143(1)" },
]
export const RETURN_FILED = [
    { label: ' U/s 139(1) Original Return', value: "U/s 139(1) Original Return" },
    { label: ' U/s 148 Reassessment', value: "U/s 148 Reassessment" },
    { label: ' U/s 139(4) Belated Return', value: "U/s 139(4) Belated Return" },
    { label: ' U/s 139(5) Revised Return', value: "U/s 139(5) Revised Return" },
    { label: ' U/s 119(2)(b) In Response to Condonation Request', value: "U/s 119(2)(b) In Response to Condonation Request" },
    { label: ' U/s 139(8A) Updated Returnt', value: "U/s 139(8A) Updated Return" },
    { label: ' U/s 142(1) In Response to Notice', value: "U/s 142(1) In Response to Notice" },
    { label: ' QuestionU/s 153C In Response to Notice of Fact', value: "U/s 153C In Response to Notice" },
    { label: ' U/s 139(9) In Response to Defective Return Notice', value: "U/s 139(9) In Response to Defective Return Notice" },
]

export const ITR_Form_Type = [
    { label: ' ITR5 Original', value: "ITR5 Original" },
    { label: ' ITR5 Revised', value: "ITR5 Revised" },
    { label: ' ITR6 Original', value: "ITR6 Original" },
    { label: ' ITR6 Revised', value: "ITR6 Revised" },
    { label: ' ITR7 Original', value: "ITR7 Original" },
    { label: ' ITR7 Revised', value: "ITR7 Revised" },
]

// DT Issue list
export const DT_ISSUE_DROPDOWN_PENALTY = [
    {
        group: 'Penalty',
        items: [
            { id: 76, name: 'Penalty for under reporting and misreporting of income u/s 270A' },
            { id: 77, name: 'Failure to furnish returns, comply with notices, concealment of income, etc u/s 271' },
            { id: 78, name: 'Failure to keep, maintain or retain books of account, documents, etc u/s 271A' },
            { id: 79, name: 'Penalty for failure to keep and maintain information and document, etc , in respect of certain transactions u/s 271AA' },
            { id: 80, name: 'Penalty where search has been initiated u/s 271AAA' },
            { id: 81, name: 'Penalty where search has been initiated u/s 271AAB' },
            { id: 82, name: 'Penalty in respect of certain income u/s 271AAC' },
            { id: 83, name: 'Failure to get accounts audited u/s 271B' },
            { id: 84, name: 'Penalty for failure to furnish report under section 92E u/s 271BA' },
            { id: 85, name: 'Failure to subscribe to the eligible issue of capital u/s 271BB' },
            { id: 86, name: 'Penalty for failure to deduct tax at source u/s 271C' },
            { id: 87, name: 'Penalty for failure to collect tax at source u/s 271CA' },
            { id: 88, name: 'Penalty for failure to comply with the provisions of section 269SS u/s 271D' },
            { id: 89, name: 'Penalty for failure to comply with provisions of section 269ST u/s 271DA' },
            { id: 90, name: 'Penalty for failure to comply with the provisions of section 269T u/s 271E' },
            { id: 91, name: 'Penalty for failure to furnish return of income u/s 271F' },
            { id: 92, name: 'Penalty for failure to furnish statement of financial transaction or reportable income u/s 271FA' },
            { id: 93, name: 'Penalty for furnishing inaccurate statement of financial transaction or reportable account u/s 271FAA' },
            { id: 94, name: 'Penalty for failure to furnishing statement or information or document by an eligible investment fund u/s 271FAB' },
            { id: 95, name: 'Penalty for failure to furnish return of fringe benefits u/s 271FB' },
            { id: 96, name: 'Penalty for failure to furnish information or document under section 92D u/s 271G' },
            { id: 97, name: 'Penalty for failure to furnish information or document under section 285A u/s 271GA' },
            { id: 98, name: 'Penalty for failure to furnish statements, etc u/s 271H' },
            { id: 100, name: 'Penalty for failure to furnish information or furnishing inaccurate information under section 195 u/s 271-I' },
            { id: 101, name: 'Penalty for furnishing incorrect information in reports or certificates u/s 271J' },
            { id: 102, name: 'Penalty for failure to answer questions, sign statements, furnish information, returns or statements, allow inspections, etc u/s 272A' },
            { id: 103, name: 'Penalty for failure to comply with the provisions of section 133B u/s 272AA' },
            { id: 104, name: 'Penalty for failure to comply with the provisions of section 139A u/s 272B' },
            { id: 105, name: 'Penalty for failure to comply with the provisions of section 203A u/s 272BB' },
            { id: 106, name: 'Penalty for failure to comply with the provisions of section 206CA u/s 272BBB' },
            { id: 107, name: 'False estimate of, or failure to pay, advance tax u/s 273' }
        ]
    }
];

export const DT_ISSUE_DROPDOWN = [
    {
        group: 'Income from House Property',
        items: [
            { id: 1, name: 'Income from House Property ' },
            { id: 2, name: 'Addition of Income from house property u/s 22' },
            { id: 3, name: 'Addition of Income - Under Reporting of Income in compare to 26 AS' },
            { id: 4, name: 'Addition of Income - Under Reporting in compare to AIS' },

        ]
    },
    {
        group: 'Income from Business & Profession',
        items: [
            { id: 5, name: 'Income from Business & Profession' },
            { id: 6, name: 'Disallowance of Expenses being in Capital nature' },
            { id: 7, name: 'Disallowance of Expenses being in Personal Nature ' },
            { id: 9, name: 'Disallowance of Expenses being not Related to Business' },
            { id: 10, name: 'Disallowance of Expenses being nature in Penalty' },
            { id: 11, name: 'Disallowance of Interest on TDS' },
            { id: 12, name: 'Disallowance of Penalty Paid' },
            { id: 13, name: 'Disallowance of Interest due to diversion of fund' },
            { id: 14, name: 'Disallowance of Expenditure incurred for Exempt income u/s 14A' },
            { id: 15, name: 'Disallowance of Bonus expenses' },
            { id: 16, name: 'Disallowance of Rent Expenses' },
            { id: 18, name: 'Disallowance of Salary Expenses' },
            { id: 19, name: 'Disallowance of Interest Expenses' },
            { id: 20, name: 'Disallowance of Security Transaction Tax' },
            { id: 21, name: 'Disallowance of Expenses for Scientific Research' },
            { id: 22, name: 'Disallowance of Rent, rates, taxes, repairs and insurance for buildings' },
            { id: 23, name: 'Disallowance of Repairs and insurance of machinery, plant and furniture' },
            { id: 24, name: 'Disallowance of Depreciation claimed' },
            { id: 25, name: 'Disallowance of Investment allowance' },
            { id: 26, name: 'Disallowance of Development rebate' },
            { id: 27, name: 'Disallowance of Development allowance' },
            { id: 28, name: 'Disallowance of Expenditure on acquisition of patent rights or copyrights' },
            { id: 29, name: 'Disallowance of Expenditure on know-how claimed' },
            { id: 30, name: 'Disallowance of Expenditure for obtaining right to use spectrum for telecommunication services' },
            { id: 31, name: 'Disallowance of Expenditure for obtaining license to operate telecommunication services' },
            { id: 32, name: 'Disallowance of Taxation of Foreign Exchange Fluctuation claimed u/s 43AA' },
            { id: 33, name: 'Disallowance of Certain deductions to be only on actual payment claimed u/s 43B' },
            { id: 34, name: 'Disallowance u/s 36(1)(iii) - Interest on borrowed capital' },
            { id: 35, name: 'Contribution by employer towards SAF, RPF, GF before filing of return/43B' },
            { id: 36, name: 'Contribution by employee towards SAF, RPF, GF before due date of resective acts' },
            { id: 37, name: 'Disallowance for provision of bad debts' },
            { id: 38, name: 'Disallowance on account of special reserve' },
            { id: 39, name: 'Disallowance for non deduction of TDS in case of Resident and Non Resident' },
            { id: 40, name: 'Disallowance u/s 40A(2) - Related Party Transaction' },
            { id: 41, name: 'Disallowance u/s 40A(3) - Cash Payments in excess of 10k/35k' },
            { id: 42, name: 'Disallowance u/s 40A(7) & (9) - Gratuity, SF & PF' },
            { id: 43, name: 'Other Disallowance u/s 40A' },
            { id: 44, name: 'Deduction of HO Expense in case of NR' },
            { id: 45, name: 'Addition of Income - Under Reporting of Income in compare to 26 AS' },
            { id: 46, name: 'Addition of Income - Under Reporting in compare to AIS' },
            { id: 76, name: 'Short deduction of TDS' },
            { id: 77, name: 'Subsidy on Entertainment Tax' },
            { id: 78, name: 'Disallowance on account of merger exp' },
            { id: 79, name: '36(1)(v) - Employer contb in the irrecovable trust setup by Employer himself for the benefit of Employee' },
            { id: 80, name: 'Directors remuneration in excess to be disallowed' },
            { id: 81, name: 'Disallowance of Expenses being nature in Preliminary Exp' },
            { id: 82, name: 'Amount not to be allowed as an expense for earning exempt income' },
            { id: 85, name: 'Disallowance of service tax' },
            { id: 88, name: 'Disallowance u/s 92 - Export Commission to AE ' },
            { id: 89, name: 'Disallowance u/s 92 - Royalty paid on Exports sales to AE' },
            { id: 90, name: 'Disallowance u/s 92 - Import of Goods from AE' },
            { id: 91, name: 'Disallowance u/s 92 - lower Profitability vis-a-vis Comparables' },
            { id: 92, name: 'Disallowance u/s 92 - Model fee on strip change to AE' },
            { id: 93, name: 'Disallowance of Expenses u/s 37' },
            { id: 94, name: 'Disallowance of Expenses -  Payment related to Tax' },
            { id: 95, name: 'Disallowance of Expenses u/s 36' },
            { id: 96, name: 'Other - Miscellaneous' },
        ]
    },
    {
        group: 'Income from Capital Gains',
        items: [
            { id: 47, name: 'Income from Capital Gains' },
            { id: 48, name: 'Addition of Capital Gain U/s 50 C' },
            { id: 49, name: 'Addition of capital Gain on Sale of Property' },
            { id: 50, name: 'Addition of Income - Under Reporting of Income in compare to 26 AS' },
            { id: 51, name: 'Addition of Income - Under Reporting in compare to AIS' },
        ]
    },
    {
        group: 'Income from Other Sources',
        items: [
            { id: 52, name: 'Deductible expenses u/s 57' },
            { id: 53, name: 'Addition of Income - Under Reporting of Income in compare to 26 AS' },
            { id: 54, name: 'Addition of Income - Under Reporting in compare to AIS' },
            { id: 84, name: 'Disallowance of Expenses being in the nature of Deemed Dividend' },

        ]
    },
    {
        group: 'Deductions under Chapter VI-A',
        items: [
            { id: 55, name: 'Disallowance of deduction u/s 80G donations to certain funds, charitable institutions' },
            { id: 56, name: 'Disallowance of deduction u/s 80 GGB contributions given by companies to political parties ' },
            { id: 57, name: 'Disallowance of deduction u/s 80GGC contributions given by any person to political parties.' },
            { id: 58, name: 'Disallowance of deduction u/s 80IBA in respect of profits and gains from housing projects.' },
            { id: 59, name: 'Deduction u/s 80JJAA - New employees hired during the year' },
            { id: 60, name: 'Deduction u/s 80RRB - In respect of royalty on patents' },
            { id: 83, name: 'Deductions in respect of profits and gains from industrial undertakings or enterprises engaged in infrastructure development' },

        ]
    },
    {
        group: 'Tax Credits/Mismatch',
        items: [
            { id: 61, name: 'Tax credit not given for TDS claim' },
            { id: 62, name: 'Tax credit not given for Advance Tax Paid' },
            { id: 63, name: 'Tax mismatch due to difference in 234A interest' },
            { id: 64, name: 'Tax mismatch due to difference in 234B interest' },
            { id: 65, name: 'Tax mismatch due to difference in 234C interest' },
            { id: 66, name: 'Late filing fees u/s 234F' },
            { id: 97, name: 'Demand on account of DDT (Dividend Distribution Tax)' },
            { id: 98, name: 'Refund of excess DDT (Dividend Distribution Tax)' }
        ]
    },
    {
        group: 'Unexplained Income/Expenses/Investments',
        items: [
            { id: 67, name: 'Addition of income u/s 68 on account of cash credits' },
            { id: 68, name: 'Addition of income u/s 69 on account of unexplained investments' },
            { id: 69, name: 'Addition of income u/s 69A on account of unexplained money' },
            { id: 70, name: 'AAddition of income u/s 69B on account of investments not fully undisclosed in books of accounts' },
            { id: 71, name: 'Addition of income u/s 69C on account of unexplained expenditure' },
            { id: 72, name: 'Addition of income u/s 69D on account of amount borrowed or repaid on Hundi' }
        ]
    },
    {
        group: 'Disallowance of carry forward of losses',
        items: [
            { id: 73, name: 'Disallowance of Carry forward of Losses' },
            { id: 74, name: 'Disallowance of brought forward of Losses' }
        ]
    },
    {
        group: 'Unabosorbed Dep',
        items: [
            { id: 75, name: 'Unabosorbed Dep' }
        ]
    },
    {
        group: 'TDS proceedings',
        items: [
            { id: 85, name: 'TDS short deducted and paid' },
            { id: 86, name: 'TDS not deducted' },
            { id: 87, name: 'TDS deducted but not paid' },
        ]
    },

];


export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';
export const VALID_FILE_TYPE = ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'];

export const ProAdjDateColumn: { [key: string]: string } = {
    lastRespSubOn: 'lastResponseSubmittedOn',
    parsedRequestDT: 'parsedRequestDate',
    parsedResDueDT: 'parsedResDueDate'
}

export const NoticeVcDateColumn: { [key: string]: string } = {
    parsedIssuedOnDt: 'parsedIssuedOn',
    parsedResponseDueDt: 'parsedResponseDueDate',
    parsedAdjournmentDueDt: 'parsedAdjournmentDueDate',
    parsedRequestDt: 'parsedRequestDate',
    vcAdjRqstDt: 'vcAdjRqstDate'
}
